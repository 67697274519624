import * as React from 'react'
import tw from "twin.macro"
import "@fontsource/inter"
import Seo  from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const App = tw.div`
    text-lg md:text-2xl text-black bg-white transition-all duration-300 dark:bg-black dark:text-gray-200 flex flex-wrap content-between min-h-screen
`

const IndexPage = () => {
  return (
    <App>
      <Seo title="" />
        <Header />
        <div tw="flex relative items-center px-6 mb-8 w-full md:px-20">
          <div tw="relative z-20 w-full text-4xl leading-tight md:text-7xl">
            <p className="animate-fade" tw="hidden leading-tight lg:inline-flex" data-sal="fade" data-sal-delay="50" data-sal-duration="1000">
              Sciences infirmières.
            </p>
            <p className="animate-fade" tw="inline-flex leading-tight lg:hidden" data-sal="fade" data-sal-delay="50" data-sal-duration="1000">
              Sciences infirmières.
            </p>
            <br />
            <p tw="hidden leading-tight lg:inline-flex" data-sal="fade" data-sal-delay="150" data-sal-duration="1000">
            Promotion de la santé.
            </p>
            <p tw="inline-flex leading-tight lg:hidden" data-sal="fade" data-sal-delay="150" data-sal-duration="1000">
            Promotion de la santé.
            </p>
            <br />
            <p tw="hidden leading-tight lg:inline-flex" data-sal="fade" data-sal-delay="250" data-sal-duration="1000">
            Éducation thérapeutique.
            </p>
            <p tw="inline-flex leading-tight lg:hidden" data-sal="fade" data-sal-delay="250" data-sal-duration="1000">
            Éducation thérapeutique.
            </p>
            <br />
            <p tw="hidden leading-tight lg:inline-flex" data-sal="fade" data-sal-delay="350" data-sal-duration="1000">
            Santé communautaire.
            </p>
            <p tw="inline-flex leading-tight lg:hidden" data-sal="fade" data-sal-delay="350" data-sal-duration="1000">
            Santé communautaire.
            </p>
            <br />
          </div>
          <div tw="flex absolute top-0 right-0 bottom-0 left-0 z-10 items-center w-full h-full"></div>
        </div>
        <Footer />
    </App>
  )
}

export default IndexPage


